import React, { useRef, useEffect, useState } from "react"

import Footer from "../components/footer"
import SEO from "../components/seo"
import styles from "../styles/_contact.module.scss"
import noUiSlider from "nouislider"
import { TimelineMax } from "gsap"
import AnimatedHeadline from "../components/AnimatedHeadline"
import scrollToElement from "scroll-to-element"
import $ from "jquery"
import ogImageAufwand from "../images/og-image/og-image-aufwand.png"
export default function ValueProject() {
  const [disabled, setDisabled] = useState(false)
  let subheader = useRef(null)
  let form = useRef(null)
  let type = useRef(null)
  let time = useRef(null)
  const list = [
    "Usability-Test",
    "Usablility-Review",
    "Design Konzept",
    "Animation",
    "Vektorbasierte Illustration",
    "Webseite",
    "E-Commerce / Online-Shop",
    "Landingpage",
    "Dashboard",
    "CMS",
    "Strategien",
    "Content-Erstellung",
    "Content-Management",
    "Redaktion",
  ]

  let overlay = useRef(null)
  useEffect(() => {
    handleType()
    handleTime()
    handleSlider()
  }, [])

  function handleSlider() {
    var range = document.getElementsByClassName("cb-intro-form-slider-slide")[0]

    noUiSlider.create(range, {
      start: [0, 1000],
      connect: true,
      range: {
        min: 0,
        max: 5000,
      },
      margin: 0,
      step: 50,

      orientation: "horizontal",
      format: {
        // 'to' the formatted value. Receives a number.
        to: function(value) {
          return value + " €"
        },
        // 'from' the formatted value.
        // Receives a string, should return a number.
        from: function(value) {
          return Number(value.replace(".00", ""))
        },
      },
    })
    var marginMin = document.getElementById("cb-intro-form-slider-label-min"),
      marginMax = document.getElementById("cb-intro-form-slider-label-max"),
      parent = $(".cb-intro-form-slider-slide")
    range.noUiSlider.on("update", function(values, handle) {
      if (handle) {
        marginMax.innerHTML = values[handle]
      } else {
        marginMin.innerHTML = values[handle]
      }
    })
  }
  function handleType() {
    let state = []
    let parent = $(".cb-intro-form-type .cb-intro-form-type-grid")
    parent.children().on("click", function() {
      let parentData = parent.data()
      let dataProject = $(this).data().project
      $(this).toggleClass("selected")

      if (state.includes(dataProject)) {
        state = state.filter(item => {
          if (item === dataProject) {
            return false
          }
          return true
        })
      } else {
        state.push(dataProject)
      }

      parent.attr("data-selected", state)
    })
  }

  function handleTime() {
    let state = ""
    let parent = $(".cb-intro-form-time .cb-intro-form-type-grid")

    parent.children().on("click", function() {
      let parentData = parent.data()
      let dataProject = $(this).data().project

      state = dataProject

      parent.children().removeClass("selected")
      $(this).addClass("selected")

      parent.attr("data-selected", state)
    })
  }

  // Animation after submit
  function animateAfterSuccess() {
    let tl = new TimelineMax()
    console.log(disabled)

    tl.to(`.${styles.cbOverlay}`, 0, {
      css: { visibility: "visible", display: "flex" },
    })
    tl.from(
      `.${styles.cbOverlay}`,
      0.15,
      { opacity: 0, scale: 0.7 },
      { opacity: 1, scale: 1 }
    )
  }

  function submitForm(e) {
    e.preventDefault()

    setDisabled(true)
    let projektArt = $(".cb-intro-form-type .cb-intro-form-type-grid").attr(
      "data-selected"
    )
    let name = $(".cb-intro-form-input:nth-child(1)").val()
    let email = $(".cb-intro-form-input:nth-child(2)").val()
    let about = $(".cb-intro-form-textarea textarea").val()
    let min = $("#cb-intro-form-slider-label-min").html()
    let max = $("#cb-intro-form-slider-label-max").html()
    let time = $(".cb-intro-form-time .cb-intro-form-type-grid").attr(
      "data-selected"
    )
    let obj = { projektArt, name, email, about, min, max, time }

    fetch("http://wordpress.cuuno.com/wp-json/form/aufwandsschaetzung", {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        animateAfterSuccess()
        return res
      })
      .then(data => {})
  }

  /*   function sendPostRequest() {
    $(form).submit(function(e) {
      e.preventDefault()

      /*   setDisabled(true)
        setLoading(true) 

      let projektArt = $(".cb-intro-form-type .cb-intro-form-type-grid").attr(
        "data-selected"
      )
      let name = $(".cb-intro-form-input:nth-child(1)").val()
      let email = $(".cb-intro-form-input:nth-child(2)").val()
      let about = $(".cb-intro-form-textarea textarea").val()
      let min = $("#cb-intro-form-slider-label-min").html()
      let max = $("#cb-intro-form-slider-label-max").html()
      let time = $(".cb-intro-form-time .cb-intro-form-type-grid").attr(
        "data-selected"
      )
      let obj = { projektArt, name, email, about, min, max, time }
      console.log(obj)
      $.ajax({
        url: "http://wordpress.cuuno.com/wp-json/form/aufwandsschaetzung",

        type: "POST",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(obj),
        success: function(response) {
          console.log("done")
        },
        error: function(jqXHR, textStatus, errorMessage) {
          console.log(jqXHR)
          console.log(textStatus)
          console.log(errorMessage)
        },
      })
    })
  } */

  return (
    <>
      <SEO
        title="Aufwandsschätzung. Wir schätzen ihr Projekt."
        description="Teilen Sie uns ihre Idee mit -
        wir schätzen den Aufwand und
        setzen uns mit ihnen in Verbindung."
        image={ogImageAufwand}
        defaultTitle="Aufwandsschätzung - Cuuno"
        titleTemplate="Aufwandsschätzung"
      />
      <div className="cb-container">
        <div className="cb-herosection">
          <div className="cb-herosection-wrap">
            <AnimatedHeadline
              scrollTo={"#formular"}
              offset={-100}
              mobileText="Lassen Sie uns über Ihre Idee sprechen - füllen Sie das Formular aus und wir werden uns innerhalb von 24 Stunden mit Ihnen in Verbindung setzen."
              desktopText={[
                "Teilen Sie uns ihre Idee mit -",
                "wir schätzen den Aufwand und",
                "setzen uns mit ihnen in Verbindung.",
              ]}
            />
          </div>
        </div>

        <div className="cb-intro-body">
          <div className="cb-intro-grid">
            <div className="cb-intro-grid-col">
              <form
                onSubmit={submitForm}
                id="formular"
                ref={el => (form = el)}
                className="cb-intro-form"
              >
                <div className="cb-intro-form-type">
                  <div className="cb-intro-form-header">
                    <h2>Projektart</h2>
                  </div>
                  <div className="cb-intro-form-subheader">
                    <h2>Wählen Sie die passenden Leistungen</h2>
                  </div>

                  <div
                    className="cb-intro-form-type-grid"
                    data-selected=""
                    ref={el => (type = el)}
                  >
                    {list.map(offer => {
                      return (
                        <button data-project={offer} type="button">
                          <span> {offer}</span>
                        </button>
                      )
                    })}
                  </div>
                </div>

                <div className="cb-intro-form-header">
                  <h2>Stellen Sie sich vor</h2>
                </div>
                <div className="cb-intro-form-grid">
                  <input
                    required
                    className="cb-intro-form-grid-item cb-intro-form-input"
                    type="text"
                    name="firstName"
                    placeholder="Ihr Name"
                  />
                  <input
                    required
                    className="cb-intro-form-grid-item cb-intro-form-input"
                    type="text"
                    name="lastName"
                    placeholder="Ihre Email"
                  />
                </div>

                <div className="cb-intro-form-textarea">
                  <div className="cb-intro-form-header">
                    <h2>Über das Projekt</h2>
                  </div>
                  <textarea required></textarea>
                </div>
                <div className="cb-intro-form-slider" data-budget="">
                  <div className="cb-intro-form-header">
                    <h2>Budget</h2>
                  </div>
                  <div className="cb-intro-form-subheader">
                    <h2>
                      Wenn wir Ihr Budget kennen können wir sicherstellen, dass
                      wir Ihre Erwartungen erfüllen können.
                    </h2>
                  </div>

                  <div className="cb-intro-form-slider-slide"></div>
                  <div className="cb-intro-form-slider-label">
                    <div id="cb-intro-form-slider-label-min"></div>
                    <div id="cb-intro-form-slider-label-max"></div>
                  </div>
                </div>

                <div className="cb-intro-form-time">
                  <div className="cb-intro-form-header">
                    <h2>Zeitspanne</h2>
                  </div>
                  <div
                    className="cb-intro-form-type-grid"
                    data-selected=""
                    ref={el => (time = el)}
                  >
                    <button data-project="Not specified" type="button">
                      <span>Nicht wichtig</span>
                    </button>
                    <button data-project="< 6 months" type="button">
                      <span> &lt; 6 Monate</span>
                    </button>
                    <button data-project="> 6 months" type="button">
                      <span> &gt; 6 Monate</span>
                    </button>
                  </div>
                </div>

                <button
                  disabled={disabled}
                  type="submit"
                  className="cb-intro-submit"
                >
                  <div className="cb-button -color-bg-blue">
                    <p>Schätzen</p>
                    <span>
                      <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
                        <path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z" />
                        <path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z" />
                      </svg>
                    </span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div ref={el => (overlay = el)} className={`contact ${styles.cbOverlay}`}>
        <div className={`contact ${styles.cbOverlayWrap}`}>
          <div class="cb-header">
            <h1>Vielen Dank für ihre Nachricht.</h1>
          </div>
          <div className={`contact ${styles.cbOverlaySubheader}`}>
            <h1>Wir melden uns.</h1>
          </div>
          <div
            onClick={e => {
              setDisabled(false)
              $(overlay)
                .first()
                .fadeOut(150)
            }}
            class="cb-button -color-bg-blue"
          >
            <p>Zurück zur Webseite</p>
            <span>
              <svg version="1.1" viewBox="0 0 492 492">
                <path
                  d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
 c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
 L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
 c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
 c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
 C492,219.198,479.172,207.418,464.344,207.418z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
